import { useSearchStateStore } from "@/Stores/SearchState";

export function GetSearch(to) {
    return axios.get(`/searches/${ to.params.index }`)
        .then(response => {
            useSearchStateStore().updateSearch(response.data)
            to.params.search = response.data
            return true
        })
        .catch(error => {
            return { name: 'NotFound' }
        })
}
