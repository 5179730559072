import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useAuthStore } from '@/Stores/Auth'

export const useNotificationsStore = defineStore('notifications', () => {
    const authStore = useAuthStore()

    // the array of notification objects
    const stack = ref([])

    // push a notification to the stack
    function notify(options) {
        let notification = createNotification(options)
        stack.value.push(notification)

        if (notification.autodismiss) {
            setTimeout(() => {
                dismiss(notification.id)
            }, notification.timeout)
        }
    }

    function notifyType(type = 'info', options = {}) {
        let params = createNotification({ ...options, ...{ type: type }})

        return notify(params)
    }

    function success(options) {
        notifyType('success', options)
    }

    function info(options) {
        notifyType('info', options)
    }

    function warning(options) {
        notifyType('warning', options)
    }

    function error(options) {
        notifyType('error', options)
    }

    // create a notification object
    function createNotification(options) {
        return {
            id: Date.now()+stack.value.length,
            title: options.title ?? '',
            message: options.message ?? '',
            type: options.type ?? 'info',
            show: true,
            autodismiss: options.autodismiss ?? true,
            timeout: options.timeout ?? 5000,
            position: authStore.user.preferences?.system_message ?? 'topright'
        }
    }

    // dismiss a notification
    function dismiss(id) {
        let getIndex = () => stack.value.findIndex(notification => notification.id == id)
        let index = getIndex()

        if (index > -1 && stack.value[index].show) {
            stack.value[index].show = false
            setTimeout(() => stack.value.splice(getIndex(), 1), 300)
        }
    }

    return { stack, notify, dismiss, success, info, warning, error }
})
