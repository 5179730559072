import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useAlertRunStore = defineStore('alert_run', () => {

    /**
     * The alert that the run belongs to
     */
    const alert = ref(null)

    /**
     * The alert run
     */
    const alertRun = ref(null)

    /**
     * Checksum value used to quickly determine if data is stale on the server side
     */
    const checksum = ref(null)

    /**
     * Fetch the alert run
     */
    const fetchAlertRun = (uuid) => {
        return new Promise((resolve, reject) => {
            axios.get(`/alert_runs/${ uuid }?checksum=${ checksum.value }`)
                .then(response => {
                    checksum.value = response.data.checksum

                    if (! response.data.match) {
                        alertRun.value = response.data.data
                        alert.value = alertRun.value.alert

                        // Deduplicate the alert model
                        delete alertRun.value['alert']
                    }

                    resolve()
                })
                .catch(error => {
                    reject(error)
                })

                if (alertRun.value && alertRun.value.key === uuid) {
                    resolve()
                }
        });
    }

    return { fetchAlertRun, alert, alertRun }
})
