<script setup>
import { computed } from 'vue'

const props = defineProps({
    type: {
        type: String,
        default: 'submit',
    },
    color: {
        type: String,
        default: 'blue',
    },
    size: {
        type: String,
        default: 'md',
    },
    class: {
        type: String,
        default: '',
    },
    disabled: {
        type: Boolean,
        default : false,
    },
    align: {
        type: String,
        default: 'center',
    }
})

const baseClasses = 'inline-flex items-center font-medium focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:ring-offset-slate-900 transition ease-in-out duration-150 disabled:opacity-60 select-none'

const alignClasses = computed(() => {
    return {
        'left': 'justify-start',
        'center': 'justify-center',
        'right': 'justify-end',
    }[props.align.toString()]
})

const sizeClasses = computed(() => {
    return {
        'xxs': 'px-1.5 py-1 text-xs rounded-md',
        'xs': 'px-2.5 py-1.5 text-xs rounded-md',
        'sm': 'px-3 py-2 text-sm rounded-md',
        'md': 'px-4 py-2 text-sm rounded-md',
        'lg': 'px-4 py-2 text-base rounded-md',
        'xl': 'px-6 py-3 text-base rounded-md',
        'circle': 'rounded-full',
    }[props.size.toString()]
})

const colorClasses = computed(() => {
    return {
        'red': 'bg-red-600 hover:bg-red-700 text-white',
        'teal': 'bg-teal-500 hover:bg-teal-600 text-white',
        'blue': 'bg-blue-600 hover:bg-blue-700 text-white',
        'green': 'bg-green-800 hover:bg-green-700 text-white',
        'indigo': 'bg-indigo-600 hover:bg-indigo-700 text-white',
        'gray': 'bg-slate-500 hover:bg-slate-600 text-white',
        'lightBlue': 'bg-blue-100 hover:bg-blue-200 text-blue-500 dark:bg-blue-600/20 dark:text-blue-100 dark:hover:bg-blue-600/30 dark:hover:text-white',
        'lightGray': 'bg-slate-200 hover:bg-slate-300 text-slate-800 dark:bg-slate-600 dark:hover:bg-slate-700 dark:text-white',
        'lighterGray': 'bg-slate-100 hover:bg-slate-200 text-slate-600 dark:bg-slate-600 dark:hover:bg-slate-700 dark:text-white',
        'white': 'border border-slate-300 bg-white text-slate-700 dark:border-slate-900 dark:bg-slate-800 dark:text-white',
        'transparent': 'text-slate-500 hover:text-slate-900 hover:bg-slate-100 focus:bg-slate-100 focus:text-slate-900 dark:text-slate-300 dark:hover:bg-slate-700 dark:focus:bg-slate-700 dark:focus:text-white dark:hover:text-white'
    }[props.color.toString()]
})

const injectedClasses = computed(() => props.class.toString())
</script>

<template>
    <button :type="type" :disabled="props.disabled" :class="[baseClasses, alignClasses, sizeClasses, colorClasses, injectedClasses]">
        <slot />
    </button>
</template>
