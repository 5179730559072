import { usePatentSearch } from '@/Composables/PatentSearch'

export function GetSharedPatent(to) {

    const { request } = usePatentSearch()
    request.updateRows(1)
    request.updateStart(0)
    request.updateGrouping('none')

    return axios.post(`patents/public/search/browse`, {
        ...request.publicPayload,
        key: to.params.key,
        type: 'shared_patent',
        ucid: to.params.ucid,
        startingTab: to.query.tab ?? 'overview'
    }).then(response => {
        if (response.data.successful && response.data.docs.length > 0) {
            to.params.doc = response.data.docs[0]
            return true
        }
        return { name: 'NotFound' }
    })
    .catch(error => {
        return { name: 'NotFound' }
    })
}
