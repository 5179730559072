<script setup>
import { computed } from 'vue'
import { DialogPanel } from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/solid'
import Button from '@/Components/FormElements/Button.vue'


const props = defineProps({
    size: {
        type: String,
        required: false,
        default: 'md',
        validator: (value) => {
            return ['sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl', 'fit'].includes(value)
        }
    },
    overflow: {
        type: String,
        required: false,
        default: 'overflow-hidden',
    },
    hideCloseButton: {
        type: Boolean,
        required: false,
        default: false,
    }
})

const emit = defineEmits(['close'])

const size = computed(() => {
    return {
        "sm": 'max-w-lg',
        "md": 'max-w-xl',
        "lg": 'max-w-2xl',
        "xl": 'max-w-3xl',
        "2xl": 'max-w-4xl',
        "3xl": 'max-w-5xl',
        "4xl": 'max-w-6xl',
        "5xl": 'max-w-7xl',
        "fit": 'max-w-fit',
    }[props.size.toString()]
})

const close = () => {
    emit('close')
}
</script>

<template>
    <DialogPanel
        class="relative w-full rounded-md bg-white dark:bg-slate-800 text-left align-middle shadow-xs transition-all p-6"
        :class="[size,overflow]"
    >
        <slot />

        <!-- Close Button -->
        <Button
            v-if="!hideCloseButton"
            class="top-4 right-4 absolute p-1"
            color="transparent"
            size="circle"
            @click="close"
            style="margin: 0"
        >
            <XIcon class="h-4 w-4" />
        </Button>
    </DialogPanel>
</template>
