import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const useFolderCategoriesStore = defineStore('folder_categories', () => {

    /**
     * Array of folder categories in the current user's organization
     */
    const folderCategories = ref(null)

    /**
     * Boolean property to allow UI to show that data is being refreshed
     */
    const updating = ref(false)

    /**
     * Checksum value used to quickly determine if data is stale on the server side
     */
    const checksum = ref()

    /**
     * If we already have data then resolve the promise immediately for quicker site response
     */
    const hasData = computed(() => {
        return !(folderCategories.value == null)
    });

    /**
     * Fetch folder categories
     */
    const fetchFolderCategories = () => {
        updating.value = true

        return new Promise((resolve, reject) => {
            axios.get('/folder_categories?checksum='+checksum.value).then(response => {
                checksum.value = response.data.checksum
                if (!response.data.match) {
                    folderCategories.value = response.data.data
                }
                updating.value = false
                resolve()
            }, err => {
                updating.value = false
                reject()
            });

            if (hasData.value){
                resolve()
            }
        });
    }

    const getFolderCategories = computed(() => {
        if (hasData) {
            return folderCategories.value
        }

        return []
    });


    return { fetchFolderCategories, getFolderCategories, updating, hasData }
})
