export function GetAlertRuns(to) {
    return axios.get(`/alerts/${ to.params.id }/alert_runs`)
        .then(response => {
            to.params.alert = response.data
            return true
        })
        .catch(error => {
            return { name: 'NotFound' }
        })
}
