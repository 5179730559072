import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const useCustomFieldSchemeStore = defineStore('custom_field_scheme', () => {

    /**
     * The scheme loaded in from the backend
     */
    const scheme = ref(null)

    /**
     * Boolean property to allow UI to show that data is being refreshed
     */
    const updating = ref(false)

    /**
     * Checksum value used to quickly determine if data is stale on the server side
     */
    const checksum = ref()

    /**
     * If we already have data then resolve the promise immediately for quicker site response
     */
    const hasData = computed(() => {
        return !(scheme.value == null)
    })

    /**
     * Fetch scheme from API
     */
    function fetchScheme(scheme_id) {
        updating.value = true

        let endpoint = `/custom_field_schemes/${ scheme_id }?checksum=${ checksum.value }`

        return new Promise((resolve, reject) => {
            axios.get(endpoint).then(response => {
                checksum.value = response.data.checksum
                if (!response.data.match) {
                    scheme.value = response.data.data
                }
                updating.value = false
                resolve()
            }, err => {
                updating.value = false
                reject()
            })

            if (hasData.value && scheme.value.id === scheme_id) {
                resolve()
            }
        })
    }

    function updateFieldsOrder(fieldsOrder) {
        updating.value = true

        let endpoint = `/custom_field_schemes/${ scheme.value.id }/fields`

        return new Promise((resolve, reject) => {
            axios.patch(endpoint, fieldsOrder)
                .then(response => {
                    scheme.value = response.data
                    updating.value = false
                    resolve()
                }, err => {
                    updating.value = false
                    reject()
                })
        })
    }


    const getScheme = computed(() => {
        if (hasData) {
            return scheme.value
        }

        return {}
    })


    return { fetchScheme, getScheme, updating, hasData, scheme, updateFieldsOrder }
})
