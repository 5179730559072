import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const useWorkspaceLabelsStore = defineStore('workspace_labels', () => {

    /**
     * The labels loaded in from the backend
     */
    const labels = ref(null)

    /**
     * Boolean property to allow UI to show that data is being refreshed
     */
    const updating = ref(false)

    /**
     * Checksum value used to quickly determine if data is stale on the server side
     */
    const checksum = ref()

    /**
     * If we already have data then resolve the promise immediately for quicker site response
     */
    const hasData = computed(() => {
        return Array.isArray(labels.value) && labels.value.length > 0
    })

    /**
     * Fetch workspace labels from API
     */
    const fetchLabels = () => {
        updating.value = true

        return new Promise((resolve, reject) => {
            axios.get('/user/workspace_labels?checksum='+checksum.value).then(response => {
                checksum.value = response.data.checksum
                if (!response.data.match) {
                    labels.value = response.data.data
                }
                updating.value = false
                resolve()
            }, err => {
                updating.value = false
                reject()
            })

            if (hasData.value){
                resolve()
            }
        })
    }

    const getLabels = computed(() => {
        if (hasData) {
            return labels.value
        }

        return []
    })

    return { fetchLabels, getLabels, updating, hasData, labels }
})
