export function GetExport(to) {

    if (to.params.id === 'pending') {
        return true
    }

    return axios.get(`/exports/${ to.params.id }`)
        .then(response => {
            to.params.export = response.data
            return true
        })
        .catch(error => {
            return { name: 'NotFound' }
        })
}
