import { reactive } from 'vue'
import cloneDeep from 'lodash'

export function useForm(...args) {
    const data = (typeof args[0] === 'string' ? args[1] : args[0]) || {}
    let defaults = cloneDeep(data)
    let transform = data => data

    return reactive({
        ...data,
        errors: {},
        hasErrors: false,
        processing: false,
        successful: false,
        signal: null,
        data() {
            return Object
                .keys(data)
                .reduce((carry, key) => {
                    carry[key] = this[key]
                    return carry
                }, {})
        },
        transform(callback) {
            transform = callback

            return this
        },
        payload() {
            return transform(this.data())
        },
        defaults(key, value) {
            if (typeof key === 'undefined') {
                defaults = this.data()
            } else {
                defaults = Object.assign(
                    {},
                    cloneDeep(defaults),
                    value ? ({ [key]: value }) : key,
                )
            }

            return this
        },
        reset(...fields) {
            let clonedDefaults = cloneDeep(defaults).__wrapped__
            if (fields.length === 0) {
                Object.assign(this, clonedDefaults)
            } else {
                Object.assign(
                    this,
                    Object
                        .keys(clonedDefaults)
                        .filter(key => fields.includes(key))
                        .reduce((carry, key) => {
                            carry[key] = clonedDefaults[key]
                            return carry
                        }, {}),
                )
            }

            return this
        },
        setError(key, value) {
            Object.assign(this.errors, (value ? { [key]: value } : key))

            this.hasErrors = Object.keys(this.errors).length > 0

            return this
        },
        clearErrors(...fields) {
            this.errors = Object
                .keys(this.errors)
                .reduce((carry, field) => ({
                    ...carry,
                    ...(fields.length > 0 && !fields.includes(field) ? { [field] : this.errors[field] } : {}),
                }), {})

            this.hasErrors = Object.keys(this.errors).length > 0

            return this
        },
        submit(method, url, options = {}, formData = false) {

            const data = this.payload()

            const _options = {
                onSuccess: (response) => {
                    this.processing = false
                    this.clearErrors()
                    this.successful = true

                    if (typeof options.onSuccess === 'function') {
                        options.onSuccess(response)
                    }
                },
                onError: response => {
                    this.processing = false
                    this.clearErrors()

                    if (typeof response?.data !== 'undefined' && response.data.errors) {
                        this.setError(response.data.errors)
                    }

                    if (typeof options.onError === 'function') {
                        options.onError(response?.data ?? {})
                    }
                },
            }

            this.signal = new AbortController().signal
            this.successful = false
            this.processing = true

            return axios[method](url, data, {
                headers: {
                    'Content-Type': (formData)?'multipart/form-data':'application/json',
                }
            })
                .then(_options.onSuccess)
                .catch(error => _options.onError(error))
        },
        get(url, options) {
            return this.submit('get', url, options)
        },
        post(url, options, formData = false) {
            return this.submit('post', url, options, formData)
        },
        put(url, options) {
            return this.submit('put', url, options)
        },
        patch(url, options) {
            return this.submit('patch', url, options)
        },
        delete(url, options) {
            return this.submit('delete', url, options)
        },
        cancel() {
            this.signal.abort()
            this.processing = false
            this.successful = false
        }
    })
}

export function useFormStrings(editing = false) {
    return {
        type: editing ? 'Edit' : 'Create',
        action: editing ? 'Save' : 'Create',
        actionCompleted: editing ? 'updated' : 'created'
    }
}
