import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { loadLanguageAsync } from 'laravel-vue-i18n'

export const useAuthStore = defineStore('auth', () => {

    // The authenticated user instance
    const user = ref({})

    // the app current version
    const updateName = ref('')
    const appBannerText = ref('')
    const appBannerLink = ref('')

    // determine if the current user is authenticated
    const isAuthenticated = computed(() => typeof user?.value?.id !== 'undefined')

    // determine if the current user is an admin
    const isAdmin = computed(() => isAuthenticated && user.value.is_admin)

    const isEndUser = computed(() => isAuthenticated && user.value.is_end_user)

    // Tracking module specific check to determine if a tracking reference must be set
    const trackingRequired = computed(() => {
        let moduleConfig = getModule('tracking')?.configuration

        return moduleConfig?.enabled === true && moduleConfig?.required === true
    })

    /**
     * Request log out of the application
     */
    async function logout() {
        try {
            await axios.post('/logout')
            user.value = null
        } catch (e) {
            console.error(e)
        }

    }

    /**
     * Retrieve the authenticated user
     */
    async function getUser() {
        await axios.get('/user')
            .then(response => {
                user.value = response.data
                updateLocale()
                updateApp()
            })
    }

    function getCurrentUser() {
        return user.value
    }

    function setPreference(preference, value) {
        return new Promise((resolve, reject) =>  {
            axios.put(`/preferences/${ preference }`, { value })
                .then(response => {
                    user.value.preferences = response.data
                    resolve(response.data)
                }, err => {
                    reject(err.data)
                });
        });
    }

    function checkKey(ucid, key) {
        return new Promise((resolve, reject) =>  {
            axios.get(`/auth/key/${ucid}/${key}`)
                .then(response => {
                    resolve(response.data)
                }, err => {

                    reject(err.data)
                });
        });
    }

    function getModule(name) {
        return user.value.organization?.modules?.find(module => module.name === name)
    }

    // Check if the authenticated user's organization has a module
    function hasModule(name) {
        return isAuthenticated.value && !!getModule(name)
    }

    function moduleEnabled(names) {

        // If names is not an array, convert it to one
        const moduleNames = Array.isArray(names) ? names : [names];

        // Check if all specified modules are enabled
        return moduleNames.every(name => {
            const module = getModule(name);
            // Check if the module exists and is enabled
            // added `?? false` to prevent the function to return undefined
            return (module && module.configuration.enabled === true) ?? false;
        });
    }

    function moduleEnabledNonUser(name) {
        //allow for non logged in users to access
        if(!user.value.organization) {
            return true
        }

        const module = getModule(name)

        return (module && module.configuration.enabled === true) ?? false
    }

    function updateLocale() {
        if (user.value?.preferences?.locale) {
            loadLanguageAsync(user.value.preferences.locale)
        }
    }

    // Update the app version, banner text and banner link, and make it available to the app
    function updateApp() {
        const { app_update } = user.value;

        if (!app_update) {
            return;
        }

        const { update_name, banner_text, banner_link } = app_update;

        updateName.value = update_name;
        appBannerText.value = banner_text;
        appBannerLink.value = banner_link;
    }

    return {
        isAuthenticated,
        isAdmin,
        isEndUser,
        user,
        updateName,
        appBannerText,
        appBannerLink,
        logout,
        getUser,
        getCurrentUser,
        setPreference,
        hasModule,
        getModule,
        moduleEnabled,
        moduleEnabledNonUser,
        checkKey,
        updateLocale,
        trackingRequired
    }

})
