import { defineStore } from 'pinia'
import { ref, computed, markRaw } from 'vue'

export const useDynamicModalStore = defineStore('dynamic_modal', () => {

    // the modal's open state
    const isOpen = computed(() => stack.value.some(modal => modal.isOpen))

    // the modal history stack
    const stack = ref([])

    // Open a modal
    const open = (modal, props = {}, dismissible=true) => {

        stack.value.push({
            modal: markRaw(modal),
            props: props,
            isOpen: true,
            dismissible: dismissible
        })
    }

    // Close a modal
    const close = (forceClose = false) => {
        if (forceClose) {
            stack.value.forEach(item => item.isOpen = false)
            setTimeout(() => stack.value = [], 500)
        } else if (stack.value[stack.value.length-1]) {
            stack.value[stack.value.length-1].isOpen = false
            setTimeout(() => stack.value.pop(), 500)
        }
    }

    return { isOpen, open, close, stack }
})
