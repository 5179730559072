import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { useNotificationsStore } from '@/Stores/Notifications'
import { useTemporaryDownloadLink } from '@/Composables/TemporaryDownloadLink'

export const useExportsStore = defineStore('exports', () => {

    const notifications = useNotificationsStore()

    const exports = ref(null)

    const updating = ref(false)

    const checksum = ref()

    const downloading = ref(false)

    const hasData = computed(() => {
        return Array.isArray(exports.value) && exports.value.length > 0
    })

    const getExports = computed(() => {
        if (hasData.value) {
            return exports.value
        }

        return []
    })

    const lastUsed = computed(() => {
        if (hasData.value) {
            return exports.value[0]
        }
        return null
    })

    function fetchExports() {
        updating.value = true

        return new Promise((resolve, reject) => {
            axios.get('/exports?checksum='+checksum.value).then(response => {
                checksum.value = response.data.checksum
                if (!response.data.match) {
                    exports.value = response.data.data
                }
                updating.value = false
                resolve()
            }, err => {
                updating.value = false
                reject()
            })

            if (hasData.value){
                resolve()
            }
        })
    }

    function getExportById(id) {
        return getExports.value.find(item => item.id === id)
    }

    function download(searchExport) {
        if (! downloading.value && searchExport.status === 'finished') {
            downloading.value = true

            axios.get(`/exports/${ searchExport.id }/download`, { responseType: 'blob' })
                .then(response => {
                    const url = URL.createObjectURL(new Blob([response.data]))
                    useTemporaryDownloadLink(url, searchExport.filename)
                    notifications.notify({ type: 'success', title: 'Download Complete' })
                })
                .catch(error => {
                    let message = error.status === 404 ? 'Export not found.' : 'Could not download this export.'
                    notifications.notify({ type: 'error', title: 'Download Failed', message: message })
                })
                .finally(() => downloading.value = false)
        }
    }

    return { fetchExports, getExportById, download, getExports, lastUsed, updating, hasData, exports, downloading }
})
