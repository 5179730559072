const generalFields = [
    'ag',
    'ai',
    'an',
    'ap',
    'cc',
    'cd',
    'cp',
    'cpc',
    'ct',
    'design',
    'ds',
    'epr',
    'fda',
    'fdaai',
    'fdaan',
    'fdapn',
    'grant',
    'ic8',
    'ic',
    'ine',
    'inv',
    'inx',
    'ipc',
    'jci',
    'jct',
    'kd',
    'lc',
    'na',
    'nc',
    'ncla',
    'nctb',
    'ndra',
    'nicla',
    'nnplct',
    'np',
    'nplct',
    'pa',
    'uo',
    'uox',
    'patentstatus',
    'pacc',
    'pae',
    'pax',
    'plant',
    'pn',
    'ppa',
    'ppax',
    'pr',
    'prc',
    'prs',
    'prsd',
    'prsc',
    'sc',
    'sp',
    'tiab',
    'uc',
    'upc',
    'utility',
    'xpn',
    'folder',
    'f',
    'search',
    's',
    'alive',
    'idlist',
    'dead',
    'mainipc',
    'maincpc',
    'ipci',
    'cpci',
    'app',
    'fam',
    'efam',
    'fam_cc',
    'fam_na',
    'fam_nc',
    'fam_np',
    'efam_cc',
    'efam_na',
    'efam_nc',
    'efam_np',
    'epval'
]

const flagFields = [
    'abexists',
    'claexists',
    'cpcexists',
    'descexists',
    'exampleexists',
    'fdaexists',
    'ipcexists',
    'ipcrexists',
    'ipcexists',
    'jpfiexists',
    'jpftexists',
    'loexists',
    'tiexists',
    'ucexists',
    'litigationexists',
]

const countryConstraintFields = [
    'aab',
    'ab',
    'acl',
    'adsc',
    'aex',
    'afirstcl',
    'aft',
    'aindcl',
    'ata',
    'atac',
    'ati',
    'cl',
    'ex',
    'figdsc',
    'firstcl',
    'ft',
    'indcl',
    'mtab',
    'mtcl',
    'mtdsc',
    'mtex',
    'mtfirstcl',
    'mtft',
    'mtindcl',
    'mtta',
    'mttac',
    'mtti',
    'qft',
    'qti',
    'qab',
    'qcl',
    'qtiab',
    'qtac',
    'ta',
    'tac',
    'ti',
]

const rangeFields = [
    'prd',
    'epr',
    'fam_eprd',
    'fam_epd',
    'fam_lpd',
    'efam_eprd',
    'efam_epd',
    'efam_lpd',
]

const countryConstraintRangeFields = [
    'ad',
    'apd',
    'pd',
    'fam_epr',
    'efam_epr',
]

export function useQueryLanguageDefinition() {
    return {
        case_insensitive: true,
        classNameAliases: {
            slot: "string",
            "message-name": "string",
        },
        contains: [
            // String definition
            {
                scope: 'string',
                begin: '"',
                end: '"',
            },

            // General Fields [field=]
            {
                begin: [
                    new RegExp(`\\b(${ generalFields.join('|') })\\b`),
                    /(\s?)+=/
                ],
                beginScope: {
                    1: "field",
                }
            },

            // Indicating that a field actually exists [field=]
            {
                begin: [
                    new RegExp(`\\b(${ flagFields.join('|') })\\b`),
                    /(\s?)+=/,
                ],
                beginScope: {
                    1: "field",
                }
            },

            // Fields with CC constraints [field(cc)(lc)=]
            {
                begin: [
                    new RegExp(`\\b(${ countryConstraintFields.join('|') })`),
                    /([a-z]{2})?(_[a-z]{2})?\b/,
                    /(\s?)+=/,
                ],
                beginScope: {
                    1: "field",
                    2: "field",
                }
            },

            // Range Fields
            {
                begin: [
                    new RegExp(`\\b(${ rangeFields.join('|') })\\b`),
                    /(\s?)+(>=?|<=?|=)/,
                ],
                beginScope: {
                    1: "field",
                }
            },

            // Range fields with CC constraints [field(cc)><=]
            {
                begin: [
                    new RegExp(`\\b(${ countryConstraintRangeFields.join('|') })`),
                    /([a-z]{2})?(_[a-z]{2})?\b/,
                    /(\s?)+(>=?|<=?|=)/
                ],
                beginScope: {
                    1: "field",
                    2: "field",
                }
            },

            // Citation fields
            {
                begin: [
                    /CT[ABF](_(AP|TP|EX(TP)?|NOV(OBV)?|OBV|(US)?(102(103)?|103)|[ADEILOPRTXY]|CTNF|CTFR))*/,
                    /(\s?)+=/
                ],
                beginScope: {
                    1: "field"
                }
            },
            //anyN field
            {
                begin: [
                    /any\d+/,
                    /(\s?)+\(/
                ],
                beginScope: {
                    1: "field"
                }
            },

            // "Magic" commands (i.e. not followed by an = sign)
            {
                begin: ['#list'],
                beginScope: {
                    1: "field",
                }
            },

            // Operators
            {
                begin: [
                    /\s(AND|OR|NEAR|NOT|(NOT\s?)?W(F?\/?\d+|\/?P)|\d+W)\s/,
                ],
                beginScope: {
                    1: 'operator',
                }
            },
        ],
    }
}
