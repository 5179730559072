export function VerifyPublishedFolderLink(to, from) {
    return axios.post(`/share/folder/${ to.params.uuid }`)
        .then(() => {
            return true
        })
        .catch((error) => {
            if (error.status === 410) {
                return { name: 'Expired' }
            }
            return { name: 'NotFound' }
        })
}
