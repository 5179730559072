import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const useHighlightingSchemesStore = defineStore('highlighting_schemes', () => {

    /**
     * The user's highlighting schemes
     */
    const schemes = ref(null)

    /**
     * Boolean property to allow UI to show that data is being refreshed
     */
    const updating = ref(false)

    /**
     * Checksum value used to quickly determine if data is stale on the server side
     */
    const checksum = ref();

    /**
     * If we already have data then resolve the promise immediately for quicker site response
     */
    const hasData = computed(() => {
        return !(schemes.value == null)
    });

    /**
     * Fetch user highlighting schemes from the API
     */
    const fetchSchemes = () => {

        updating.value = true

        return new Promise((resolve, reject) => {
            axios.get('/highlighting_schemes?checksum='+checksum.value).then(response => {
                checksum.value = response.data.checksum
                if (! response.data.match) {
                    schemes.value = response.data.data
                }
                updating.value = false;
                resolve()
            }, err => {
                updating.value = false;
                reject()
            });

            if (hasData.value){
                resolve()
            }
        });
    }

    const getSchemes = computed(() => {
        if (schemes.value !== null) {
            return schemes.value
        }
        return [];
    })

    const getScheme = (id) => {
        if (hasData.value) {
            return schemes.value.find(scheme => scheme.id === Number(id))
        }

        return null
    }

    return { fetchSchemes, getSchemes, getScheme, schemes, updating, hasData }
})
