import { useArrHas, useArrGet } from '@/Composables/Arr'
import { useAuthStore } from '@/Stores/Auth'

// check if highlighting is present in the document to getHl otherwise extract the value from the document
export function useDoc(attributes) {
    const auth = useAuthStore()

    const highlighting = auth.user?.preferences?.highlighting_settings.on

    return {
        ...attributes,
        get firstTitle() {
            return this.getConditionalHl('titles.0', highlighting)
        },
        get firstTitleMt() {
            return this.getConditionalHl('titles_mt.0', highlighting)
        },
        get firstEnglishTitle() {
            if (this.titles_mt) {
                return this.getConditionalHl('titles_mt.0', highlighting)
            }
            return this.getConditionalHl('titles.0', highlighting)
        },
        get firstAbstract() {
            return this.getConditionalHl('abstracts.0', highlighting)
        },
        get firstAbstractMt() {
            return this.getConditionalHl('abstracts_mt.0', highlighting)
        },
        get firstEnglishAbstract() {
            if (this.abstracts_mt) {
                return useStripPatentTags(this.getConditionalHl('abstracts_mt.0', highlighting))
            }
            return useStripPatentTags(this.getConditionalHl('abstracts.0', highlighting))
        },
        get firstEnglishAbstractNoImages() {
            let text = ''

            if (this.abstracts_mt) {
                text = useStripPatentTags(this.getConditionalHl('abstracts_mt.0', highlighting))
                return useStripImageTags(text)
            }
            text = useStripPatentTags(this.getConditionalHl('abstracts.0', highlighting))

            return useStripImageTags(text)
        },
        get firstClaims() {
            return this.getConditionalHl('firstclaims.0', highlighting)
        },
        get firstClaimsMt() {
            return this.getConditionalHl('firstclaims_mt.0', highlighting)
        },
        get firstEnglishFirstClaims() {
            if (this.firstclaims_mt) {
                return this.getConditionalHl('firstclaims_mt.0', highlighting)
            }
            return this.getConditionalHl('firstclaims.0', highlighting)
        },
        get publicationDate() {
            return this.getDate('publicationdate')
        },
        get applicationDate() {
            return this.getDate('applicationdate')
        },
        get earliestPriorityDate() {
            return this.getDate('earliestprioritydate')
        },
        get expectedExpiryDate() {
            return this.getDate('expectedexpirydate')
        },
        get expectedExpiryExplanation() {
            return this.getJsonDecoded('expectedexpiryexplanation')
        },
        get dead() {
            if (this.expectedexpirydate) {
                return useIsExpired(this.expectedexpirydate)
            }
            return null
        },
        get alive() {
            let dead = this.dead

            if (typeof dead === 'boolean') {
                return !dead
            }

            return null
        },
        get assigneesNameProbable() {
            return this.assigneesWithIndividual('assignees_name_probable')
        },
        get assigneesNameCurrent() {
            return this.assigneesWithIndividual('assignees_name_current')
        },
        get assigneesNameOriginal() {
            return this.assigneesWithIndividual('assignees_name_original')
        },
        get ultimateOwner() {
            return this.getImploded('ultimate_owner')
        },
        get inventorsName() {
            return this.getImploded('inventors_name')
        },
        get agentsName() {
            return this.getImploded('agents_name')
        },
        get earliestPriorityCountry() {
            return this.get('earliestprioritycountry.0')
        },
        get publicationNumber() {
            return useFormatPublicationNumber(this.id)
        },
        get applicationNumber() {
            if (this.has('applicationnumber')) {
                return useFormatApplicationNumber(this.applicationnumber)
            }

            return null;
        },
        get canDisplayUsFileHistory() {
            if (this.has('applicationnumber') && this.has('publicationdate')) {
                return this.publicationdate >= 19990101
            }

            return false
        },
        get srcPat() {
            return this.get('srcpat', this.id)
        },
        get hasFullText() {
            return this.tiexists || this.abexists || this.descexists || this.claexists
        },
        get hasPriorities() {
            return this.priorities && this.priorities !== '{}'
        },
        get scorePercentage() {
            if (! this.maxScore) return 0
            return parseFloat(((this.score/this.maxScore)*100).toFixed(2))
        },
        snippets(maxSnippets) {
            let snippets = []
            let orderOfPreference = [
                { field: 'abstracts_mt', title: 'Abstract MT' },
                { field: 'claims_mt', title: 'Claims MT' },
                { field: 'descriptions_mt', title: 'Description MT' },
                { field: 'titles_mt', title: 'Title MT' },
                { field: 'abstracts', title: 'Abstract' },
                { field: 'claims', title: 'Claims' },
                { field: 'descriptions', title: 'Description' },
                { field: 'titles', title: 'Title' },
            ]
            let hl = this.highlighting?.hl ?? {}

            orderOfPreference.forEach(item => {
                let highlighting = hl[item.field] ?? []

                highlighting.forEach(snippet => {
                    if (snippets.length >= maxSnippets) {
                        return snippets
                    }
                    snippets.push(`<div><i>[${ item.title }]</i> ${ useStripPatentTags(snippet) } [...]</div>`)
                })
            })

            return snippets
        },
        has(key) {
            return useArrHas(this, key)
        },
        hasAny(keys) {
            return keys.some(key => this.has(key))
        },
        hasAll(keys) {
            return keys.every(key => this.has(key))
        },
        get(key, defaultValue = null) {
            return useArrGet(this, key, defaultValue)
        },
        getConditionalHl(key, highlighting = null) {
            if (highlighting === true) {
                return this.getHl(key)
            }

            return this.get(key)
        },
        getHl(key, defaultValue = null) {
            let val = useArrGet(this.highlighting?.entireFieldHl ?? this.highlighting?.hl, key)

            /*
            If the key exists val can be an array or string depending on whether key
            specifies a particular entry in the array
            e.g. titles will return an array, but titles.0 will return a string
            */
            if (val !== null ) {
                return val
            }

            return this.get(key, defaultValue)
        },
        getImploded(key, delimiter = '; ', defaultValue = null) {
            let value = this.get(key)

            if (Array.isArray(value)) {
                return value.join(delimiter)
            }

            return defaultValue
        },
        getDate(key, defaultValue = null) {
            if (this.has(key)) {
                return useFormatPatentDate(this.get(key))
            }
            return defaultValue
        },
        getJsonDecoded(key, defaultValue = null) {
            if (this[key] && this[key] !== '{}') {
                return JSON.parse(this[key])
            }
            return defaultValue
        },
        generateDrawings() {
            let id = this.srcPat
            let drawingsCount = this.get('drawingscount', 0)
            let drawings = []

            // we have images via a separate endpoint when the drawings JSON
            // is empty, but the drawingscount is greater than 0.
            if (drawingsCount > 0 && this.drawings === '[]') {
                for (let n = 1; n < drawingsCount+1; n++) {
                    drawings.push(`/api/drawings/get-image/${ id }/${ n }`)
                }
            }
            else {
                let pd = this.publicationdate

                JSON.parse(this.drawings).forEach((drawing) => {
                    let endpoint = `/api/drawings/image/${ id }/${ pd }/${ encodeURIComponent(drawing.file) }`

                    // we need to pass the "page" for JP patents
                    if (id.startsWith('JP') && typeof drawing.page !== 'undefined') {
                        endpoint += `/${ drawing.page }`
                    }

                    if (drawing.orientation && !/^(CN|TW|WO)/.test(id)) {
                        //Empirically CN/TW/WO don't mark any drawings as being landscape so the orientation can't be relied upon cf. issue PAT-470
                        endpoint += `?orientation=${drawing.orientation}`
                    }

                    drawings.push(endpoint)
                })
            }

            return drawings
        },
        assigneesWithIndividual(field, join='; ') {
            let assignees = this.get(field, [])

            let entities = this.getApplicantsOrInventors()

            if (entities.length > 0) {
                let etAl = entities.length > 1 ? ' et al.' : ''

                assignees = assignees.map(assignee => {
                    if (assignee === 'Individual') {
                        return `${ assignee } - ${ entities[0].name+etAl }`
                    }
                    return assignee
                })
            }

            return join ? assignees.join(join) : assignees
        },
        getApplicantsOrInventors() {
            let applicants = this.getJsonDecoded('applicants', [])

            if (applicants.length) {
                return applicants
            }

            return this.getJsonDecoded('inventors', [])
        },
        // Get the assignee field based on the fallback logic
        getAssignees(field, aliases) {
            for (let index in aliases) {

                let assignees = this.get(aliases[index], [])

                if(assignees.length > 0) {
                    field = aliases[index]
                    break;
                }
            }

            return this.assigneesWithIndividual(field, false)
        },
        // Get all the documents from the simple-family-data.publications key
        get minesoftFamily()
        {
            let familyData = this.getJsonDecoded('familydata', {})

            if (familyData['simple-family-data']) {
                return familyData['simple-family-data'].publications
            }

            return []
        },
        // Get all the documents from the extended-family-data.pn.publications key
        get extendedFamily()
        {
            let familyData = this.getJsonDecoded('familydata', {})

            if (familyData['simple-family-data']) {
                return [
                    ...familyData['simple-family-data'].publications ?? [],
                    ...Object.values(familyData['extended-family-data'] || {}).flatMap(obj => obj.publications ?? [])
                ]
            }

            return []
        },
        // The subset of simple-family-data that share the same value of the "an" field with the current record
        get applicationMembers()
        {
            let familyData = this.getJsonDecoded('familydata', {})
            let members = []

            if (familyData['simple-family-data']) {
                members = familyData['simple-family-data'].publications.filter(publication => {
                    return publication.an === this.applicationnumber
                })
            }

            return members
        },
        getPatentGrouping(grouping)
        {
            if(grouping === 'simplefamily') {
                return this.minesoftFamily
            }

            if(grouping === 'extendedfamily') {
                return this.extendedFamily
            }

            return this.applicationMembers
        },

        // reorganise the json data in nationalphaseapplications so that events and dates are merged into an array that can be sent to the Table component
        getNationalPhaseApplications()
        {
            let npa_original = this.getJsonDecoded('nationalphaseapplications')

            if (! npa_original) {
                return null
            }

            let npa = []

            npa_original.forEach((row) => {

                let events  = []
                let dates = []

                Object.keys(row).forEach((key) => {
                    if (key === 'nped') {
                        events.push('Entry')
                        dates.push(useFormatPatentDate(row.nped))
                    }
                    else if (key.endsWith('date')) {
                        events.push(key.charAt(0).toUpperCase() + key.substring(1, key.length - 5))
                        dates.push(useFormatPatentDate(row[key]))
                    }
                })

                npa.push(
                    { an: row.an, source: row.source.toUpperCase(), events: events, dates: dates }
                )

            })

            return npa
        }
    }
}


export function useStripPatentTags(text) {

    // Tag to replace: <br />
    // Replacement: ' '
    // Example: CA-2055260-A1
    // Effect: ensures no random line breaking or leading whitespace before the text.

    if (typeof text === 'string') {
        return text.replaceAll(/<br \/>/g, ' ')
    }

    return text
}

export function useStripImageTags(text) {

    // Tag to replace: <img tags
    // Replacement: ''

    if (typeof text === 'string') {
        return text.replaceAll(/<img\b[^>]*>/gi, ' ')
    }

    return text
}

//Strip <pre> tags and replace one or more new lines within the pre tag with a <br> tag
export function removePreTags(text) {
    if (typeof text === 'string') {

        let newDesc = "";
        let startIndex = 0;
        const preStartTag = '<pre';
        const preEndTag = '</pre>';
        const brTag = '<br />';

        let preStart;

        while ((preStart = text.indexOf(preStartTag, startIndex)) !== -1) {
            const preTagEnd = text.indexOf('>', preStart);
            if (preTagEnd === -1) {
                break;
            }

            const preContentStart = preTagEnd + 1;
            const preContentEnd = text.indexOf(preEndTag, preTagEnd);
            if (preContentEnd === -1) {
                break;
            }

            newDesc += text.substring(startIndex, preStart);
            let preContent = text.substring(preContentStart, preContentEnd);
            preContent = preContent.replace(/[\r\n]+/g, brTag);
            newDesc += preContent;
            startIndex = preContentEnd + preEndTag.length;
        }

        if (newDesc.length > 0) {
            newDesc += text.substring(startIndex);
            text = newDesc;
        }

        return text;

    }

    return text
}


export function useFormatApplicationNumber(number) {
    let country = number.substring(0, 2)

    if (country === 'US' && number.length === 10 && /^\d+$/.test(number.substring(2))) {
        return `US ${ number.substring(2, 4) }/${ number.substring(4, 7) },${ number.substring(7, 10)}`
    }

    if (country === 'WO' && number.length === 14) {
        return `PCT/${ number.substring(2, 8) }/${ number.substring(8, 14) }`
    }

    return number
}


export function useFormatPatentDate(date) {
    if (!['number', 'string'].includes(typeof date)) return;

    date = date.toString()

    if (date.length === 8 && date > 0) {
        let y = date.substring(0,4)
        let m = date.substring(4, 6)
        let d= date.substring(6,8)
        return `${ y }-${ m }-${ d }`
    }

    return null
}

export function useFormatPublicationNumber(number) {
    return number.replace('-', '').replace('-', ' ')
}

export function useFormatPatentUCID(number) {
    let patent_ucid = number.replace(' ', '-')
    return patent_ucid.substring(0, 2) + '-' + patent_ucid.substring(2)
}

export function useIsExpired(expectedExpiryDate) {
    let date = new Date()
    let month = ''+(date.getMonth()+1)
    let day = ''+date.getDate()
    let year = ''+date.getFullYear()

    if (month.length < 2) month = '0'+month
    if (day.length < 2) day = '0'+day

    return expectedExpiryDate < parseInt(year+month+day)
}


export const usePatentRecordBaseFields = [
    'id',
    'applicationnumber',
    'publicationnumber',
    'country',
    'kindcode',
    'fdaexists',
    'tiexists',
    'abexists',
    'claexists',
    'descexists',
    'exampleexists',
    'litigationexists',
    'expectedexpiredexplanation'
]
