export function useArrGet(target, key, defaultValue = null) {
    if (typeof target !== 'object') {
        return defaultValue
    }

    if (! key.includes('.')) {
        return target[key] ?? defaultValue
    }

    key.split('.').forEach((segment) => {

        // JS thinks that "null" is an object, so if we have a target=null - target[segment] causes and error.
        // I'm fixing this by checking for null in this conditional, which will then return the default value.
        if (target !== null && typeof target === 'object') {
            target = target[segment]
        } else {
            return defaultValue
        }
    })

    return target ?? defaultValue
}

export function useArrHas(target, key) {
    if (typeof target !== 'object') {
        return false
    }

    if (! key.includes('.')) {
        return target.hasOwnProperty(key)
    }

    key.split('.').forEach((segment) => {
        if (typeof target === 'object') {
            target = target[segment]
        } else {
            return false
        }
    })

    return true
}

/**
 * Check that an array is actually empty
 * @param array
 * @returns {boolean}
 *
 * ##################################################################
 * NOTE: isEmpty is probably a better options and available in lodash
 * ##################################################################
 */
export function empty(array) {

    // If it's not an array or there is no length
    if (!Array.isArray(array) || !array.length) {
        return true;
    }

    return false;

}
