import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const useSearchTemplateStore = defineStore('search_templates', () => {
    /**
     * The user's current search template
     */
    const currentTemplate = ref(null)

    /**
     * The user's default search template
     */
    const defaultTemplate = ref(null)

    /**
     * Boolean property to allow UI to show that data is being refreshed
     */
    const updating = ref(false)

    /**
     * Get the current search template
     */
    const getCurrentTemplate = computed(() => currentTemplate.value)

    /**
     * Get the default search template
     */
    const getDefaultTemplate = computed(() => defaultTemplate.value)

    /**
     * Set the current template
     */
    const setCurrentTemplate = (template) => {
        currentTemplate.value = template
    }

    /**
     * Convenience method to unset the current template
     */
    const unsetCurrentTemplate = () => {
        setCurrentTemplate(null)
    }

    /**
     * Fetch the user's default search template
     */
    const fetchDefaultTemplate = () => {
        updating.value = true

        return new Promise((resolve, reject) => {
            axios.get('/default_template')
                .then(response => {
                    defaultTemplate.value = response.data

                    // if there is no current template, set the default template as current
                    if (! currentTemplate.value) {
                        setCurrentTemplate(defaultTemplate.value)
                    }

                    updating.value = false
                    resolve()
                })
                .catch(error => {
                    updating.value = false
                    reject()
                })

            if (defaultTemplate.value) {
                resolve()
            }
        })
    }

    return { fetchDefaultTemplate, getCurrentTemplate, setCurrentTemplate, unsetCurrentTemplate, getDefaultTemplate, updating }
})
