import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const useFolderStore = defineStore('folder', () => {

    /**
     * The folder loaded in from the backend
     */
    const folder = ref(null)

    /**
     * Boolean property to allow UI to show that data is being refreshed
     */
    const updating = ref(false)

    /**
     * Checksum value used to quickly determine if data is stale on the server side
     */
    const checksum = ref()

    /**
     * If we already have data then resolve the promise immediately for quicker site response
     */
    const hasData = computed(() => {
        return !(folder.value == null)
    })

    /**
     * If the number of documents has been reached
     */
    const limitReached = ref(false)

    /**
     * Global folder limit
     */
    const folderLimit = ref(0)

    /**
     * Global folder warning limit
     */
    const folderWarning = ref(0)

    /**
     * Fetch folder from API
     */
    const fetchFolder = (folder_id, withCount = false) => {
        updating.value = true

        let endpoint = `/folders/${ folder_id }?checksum=${ checksum.value }`

        // Pass this flag if we want to retrieve the folder with all relationships loaded
        if (withCount) {
            endpoint += '&aggregate=1'
        }

        return new Promise((resolve, reject) => {
            axios.get(endpoint).then(response => {
                checksum.value = response.data.checksum
                if (!response.data.match) {
                    folder.value = response.data.data
                }
                updating.value = false
                resolve()
            }, err => {
                updating.value = false
                reject()
            })

            if (hasData.value && folder.value.id == folder_id) {
                resolve()
            }
        })
    }

    const fetchGlobalFolderLimit = () => {
        updating.value = true;
        return new Promise((resolve, reject) => {
            axios.get(`/folder/limit`)
                .then(response => {
                    folderLimit.value = response.data.limit
                    folderWarning.value = response.data.warning
                    updating.value = false
                    resolve()
                }).catch(err => {
                updating.value = false
                reject(err)
            })
        })
    }

    const checkFolderLimit = (folder_id) => {
        updating.value = true;
        return new Promise((resolve, reject) => {
            axios.get(`/folders/${folder_id}/limit/`).then(response => {
                limitReached.value = response.data
                updating.value = false
                resolve()
            }).catch(err => {
                updating.value = false
                reject(err)
            })
        })
    }

    const getLimitReached = computed(() => {
        return limitReached.value
    })

    const getGlobalFolderLimit = computed(() => {
        return folderLimit.value
    })

    const getGlobalFolderWarning = computed(() => {
        return folderWarning.value
    })


    const getFolder = computed(() => {
        if (hasData) {
            return folder.value
        }

        return []
    })

    const folderQuery = computed(() => {
        return `folder=("${folder.value.creator.email}:${folder.value.name}")`
    })

    return { fetchFolder, checkFolderLimit, fetchGlobalFolderLimit, getLimitReached, getGlobalFolderLimit, getGlobalFolderWarning, getFolder, updating, hasData, folder, folderQuery }
})
