//Create a search for a given inchikey and redirect to the search results page
//Can't use useCreatePatentSearch here because of how the router works
export function MakeInchiKeySearch(to) {
    //create search and redirect to search page
    let options = {
        query: `AFT=$${to.params.inchikey}`,
        type: 'expert',
    }

    if (to.params.identifier && to.params.ucid) {
        switch (to.params.identifier) {
            case 'ucid':
                options.query += ` AND PN=${to.params.ucid}`
                break;
            case 'fam':
                options.query += ` AND FAM=(PN=${to.params.ucid})`
                break;
            case 'efam':
                options.query += ` AND EFAM=(PN=${to.params.ucid})`
                break;
        }
    }

    return axios.post('/searches', options)
        .then(response => {
            if (response.data.query_index) {
                return {
                    name: 'Search.Results',
                    params :{ index: response.data.query_index},
                }
            }
            else {
                return { name: 'NotFound' }
            }
        })
        .catch(error => {
            return { name: 'NotFound' }
        })
}
