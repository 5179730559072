import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const useOrganizationStore = defineStore('organization', () => {

    /**
     * Organization details
     */
    const organization = ref(null)

    /**
     * Boolean property to allow UI to show that data is being refreshed
     */
    const updating = ref(false)

    /**
     * If we already have data then resolve the promise immediately for quicker site response
     */
    const hasData = computed(() => {
        return !(organization.value == null);
    });

    /**
     * Fetch organization data from the API
     */
    const fetchOrganization = () => {

        updating.value = true;

        return new Promise((resolve, reject) => {
            axios.get('/organization').then(response => {
                organization.value = response.data;
                updating.value = false;
                resolve();
            }, err => {
                updating.value = false;
                reject();
            });

            if (hasData.value){
                resolve();
            }
        });
    }

    /**
     * Helper to return details of a given folder category as defined within an organization
     */
    const getCategoryById = (id) => {

        if (typeof organization.value.folder_categories === 'undefined') {
            return false;
        }

        // Find the specifically requested category.
        return organization.value.folder_categories.find((category) => {
            return category.id === id;
        });
    }

    /**
     * These are not called folder categories
     *
     * ALIAS
     */
    const getCategoryByID = (id) => {
        return getCategoryById(id);
    }

    /**
     * Helper to return details of all folder categories as defined within an organization
     */
    const getAllCategories = () => {

        if (typeof organization.value.folder_categories === 'undefined') {
            return [];
        }

        return organization.value.folder_categories;
    }

    /**
     * These are not called folder categories
     *
     * ALIAS
     */
    const getAllTags = () => {
        return getAllCategories();
    }

    return { fetchOrganization, getCategoryById, getCategoryByID, getAllCategories, getAllTags, organization, updating, hasData }
})
