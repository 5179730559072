import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const usePublishedSearchStore = defineStore('published_search', () => {

    /**
     * the published search
     */
    const publishedSearch = ref(null)

    /**
     * The uuid of the published search
     */
    const uuid = ref(false)

    /**
     * Fetch organization users from API
     */
    const fetchPublishedSearch = (uuid) => {
        return new Promise((resolve, reject) => {
            axios.post(`/share/search/${ uuid }`)
                .then((response) => {
                    publishedSearch.value = response.data.search
                    resolve()
                })
                .catch((error) => {
                    reject(error)
                })

        });
    }

    const fetchPublishedFolder = (uuid) => {
        return new Promise((resolve, reject) => {
            axios.post(`/share/folder/${ uuid }`)
                .then((response) => {
                    publishedSearch.value = response.data.search
                    resolve()
                })
                .catch((error) => {
                    reject(error)
                })

        });
    }

    const getQuery = computed(() => {
        return publishedSearch.value.query ?? publishedSearch.value.folder.query
    });

    const getPublishedSearch = computed(() => {
        return publishedSearch.value
    });

    return { getPublishedSearch, uuid, fetchPublishedSearch, fetchPublishedFolder, getQuery }
})
