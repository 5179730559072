import { useDoc, usePatentRecordBaseFields } from '@/Composables/PatentDocument'
import { usePatentSearch } from '@/Composables/PatentSearch'

export function GetPatent(to) {

    const { request } = usePatentSearch()
    request.updateRows(1)
    request.updateStart(0)
    request.updateGrouping('none')

    return axios.post(`patents/search/browse`, {
        ...request.filteredPayload,
        q: `id=${to.params.ucid}`,
    }).then(response => {
        if (response.data.successful && response.data.docs.length > 0) {
            to.params.doc = useDoc(response.data.docs[0])
            return true
        }
        return { name: 'NotFound' }
    })
        .catch(error => {
            return { name: 'NotFound' }
        })
}
