export function GetSubscriptionRuns(to) {
    return axios.get(`/origin-plus/subscriptions/${ to.params.id }/subscription_runs`)
        .then(response => {
            to.params.subscription = response.data
            return true
        })
        .catch(error => {
            return { name: 'NotFound' }
        })
}
