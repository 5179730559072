import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const useUserStore = defineStore('users', () => {

    /**
     * Array of organization users
     */
    const users = ref(null)

    /**
     * Boolean property to allow UI to show that data is being refreshed
     */
    const updating = ref(false)

    /**
     * Checksum value used to quickly determine if data is stale on the server side
     */
    const checksum = ref()

    /**
     * If we already have data then resolve the promise immediately for quicker site response
     */
    const hasData = computed(() => {
        return !(users.value == null)
    });

    /**
     * Fetch organization users from API
     */
    const fetchUsers = () => {
        updating.value = true

        return new Promise((resolve, reject) => {
            axios.get('/users?checksum='+checksum.value).then(response => {
                checksum.value = response.data.checksum
                if (!response.data.match) {
                    users.value = response.data.data
                }
                updating.value = false
                resolve()
            }, err => {
                updating.value = false
                reject()
            });

            if (hasData.value){
                resolve()
            }
        });
    }

    const getAllUsers = computed(() => {
        if (hasData.value) {
            return users.value
        }

        return []
    });

    const getUsers = computed(() => {
        if (hasData.value) {
            return users.value.filter(user => user.is_end_user === 0)
        }

        return []
    });

    const getEndUsers = computed(() => {
        if (hasData.value) {
            return users.value.filter(user => user.is_end_user === 1)
        }

        return []
    });

    /**
     * Helper method to extract a given user from the stored array
     */
    const getUserById = (id) => {
        return getUsers.value.find(user => user.id === id)
    }

    return { fetchUsers, getUserById, getAllUsers, getUsers, users, getEndUsers, updating, hasData }
})
