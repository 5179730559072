import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { useAuthStore } from '@/Stores/Auth'

export const useActiveTrackingReferenceStore = defineStore('active_tracking_reference', () => {

    /**
     * The auth store
     */
    const auth = useAuthStore()

    /**
     * The active tracking identifier in use
     */
    const reference = ref(null)

    /**
     * Tracking identifiers retrieved from the backend
     */
    const trackingIdentifiers = ref([])

    /**
     * Whether the initial fetch has been performed
     */
    const initialized = ref(false)

    /**
     * Whether identifiers are currently being fetched
     */
    const isFetching = ref(false)

    /**
     * Tracking logged events retrieved from the backend
     */
    const trackingLogs = ref([])

    /**
     * If we already have data then resolve the promise immediately for quicker site response
     */
    const hasReference = computed(() => {
        return typeof reference.value === 'string' && reference.value.length > 0
    });

    const hasTrackingIdentifiers = computed(() => {
        return trackingIdentifiers.value.length > 0
    });

    const getTrackingIdentifiers = computed(() => {
        if (hasTrackingIdentifiers.value) {
            return trackingIdentifiers.value
        }
        return []
    })

    /**
     * Fetch the user's tracking identifiers
     */
    function fetchActiveTrackingReference() {
        return new Promise((resolve, reject) => {
            isFetching.value = true
            axios.get(`/active_client_reference`)
                .then(response => {
                    reference.value = response.data
                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
                .finally(() => {
                    isFetching.value = false
                    initialized.value = true
                })

            if (hasReference.value){
                resolve()
            }
        });
    }

    /**
     * Get all the tracking identifiers from an organization id
     */
    function fetchTrackingIdentifiers() {
        return new Promise((resolve, reject) => {
            isFetching.value = true
            axios.get('/client_references')
                .then(response => {
                    trackingIdentifiers.value = response.data
                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
                .finally(() => {
                    isFetching.value = false
                })

            if (hasTrackingIdentifiers.value){
                resolve()
            }
        })
    }

    /**
     * Get the paginated data from Backend
     */
    function fetchTrackingLogs(filters = {}) {
        isFetching.value = true

        return new Promise(async (resolve, reject) => {
            await axios.post('/client_references/logged_events', { filters })
                .then(response => {
                    trackingLogs.value = response.data
                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
                .finally(() => {
                    isFetching.value = false
                })
        });
    }

    return {
        fetchActiveTrackingReference,
        fetchTrackingIdentifiers,
        fetchTrackingLogs,
        trackingLogs,
        getTrackingIdentifiers,
        hasTrackingIdentifiers,
        reference,
        isFetching,
        initialized,
        hasReference
    }
})
