import { defineStore } from 'pinia'
import { cloneDeep, isEmpty, startCase, toLower } from "lodash";
import {computed, ref} from "vue";
import { useRoute } from 'vue-router'

export const useFieldsAndGroupsStore = defineStore('fields_and_groups', () => {

    const route = useRoute()

    const fieldNames = ref(null);

    const hasData = computed(() => {

        if (fieldNames.value === null) {
            fieldNames.value = JSON.parse(sessionStorage.getItem('fieldNames'))
        }

        return !(fieldNames.value === null)
    })

    const groupingMethods = [
        { value: 'none', label: 'Publications'},
        { value: 'applicationnumber', label: 'Applications'},
        { value: 'simplefamily', label: 'Minesoft Families'},
        { value: 'extendedfamily', label: 'Extended Families'},
    ]

    const groupingMethod = ref(sessionStorage.getItem(route.params.analysis_id)?.grouping ?? 'simplefamily')

    const analysisSectionManuAndSectionNames = [
        {
            'assignees': [
                {'path': 'assignees', 'title': 'Top Assignees' },
                {'path': 'assignees-by-jurisdictions', 'title': '...by Jurisdictions' },
                {'path': 'assignees-by-classifications', 'title': '...by Classifications' },
                {'path': 'assignees-by-years', 'title': '...by Years' },
                {'path': 'assignees-by-application-status', 'title': '...by Status' }
            ]
        },
        {
            'classifications': [
                {'path': 'classifications', 'title': 'Top Classifications' },
                {'path': 'classifications-by-assignees', 'title': '...by Assignees' },
                {'path': 'classifications-by-inventors', 'title': '...by Inventors' },
                {'path': 'classifications-by-jurisdictions', 'title': '...by Jurisdictions' },
                {'path': 'classifications-by-years', 'title': '...by Years' }
            ]
        },
        {
            'dates': [
                {'path': 'filing-metrics-by-years', 'title': 'Filing Metrics' },
                {'path': 'key-dates', 'title': 'Key Dates' },
                {'path': 'application-years', 'title': 'Application Years' },
                {'path': 'publication-years', 'title': 'Publication Years' },
                {'path': 'grant-years', 'title': 'Grant Years' },
                {'path': 'priority-years', 'title': 'Priority Years' },
                {'path': 'expiry-years', 'title': 'Expiry Years' },

            ]
        },
        {
            'jurisdictions': [
                {'path': 'jurisdictions', 'title': 'Top Jurisdictions' },
                {'path': 'jurisdictions-by-assignees', 'title': '...by Assignees' },
                {'path': 'jurisdictions-by-years', 'title': '...by Years' },
                {'path': 'jurisdictions-by-classifications', 'title': '...by Classifications' },
                {'path': 'jurisdictions-by-inventors', 'title': '...by Inventors' },
                {'path': 'jurisdictions-by-application-status', 'title': '...by Status' }
            ]
        },
        {
            'citations': [
                {'path': 'citation-totals', 'title': 'Citation Totals' },
                {'path': 'citing-assignees', 'title': 'Citing Assignees' },
                {'path': 'cited-assignees', 'title': 'Cited Assignees' },
                {'path': 'citing-assignees-by-years', 'title': 'Citing Assignees by Years' },
                {'path': 'cited-assignees-by-years', 'title': 'Cited Assignees by Years' }
            ]
        },
        {
            'inventors': [
                {'path': 'inventors', 'title': 'Top Inventors' },
                {'path': 'inventors-by-jurisdictions', 'title': '...by Jurisdictions' },
                {'path': 'inventors-by-classifications', 'title': '...by Classifications' }
            ]
        },
        {
            'agents': [
                {'path': 'agents', 'title': 'Top Agents' },
                {'path': 'agents-by-jurisdictions', 'title': '...by Jurisdictions' }
            ]
        },
        {
            'keywords': [
                {'path': 'keyword-clustering', 'title': 'Clustering' },
                {'path': 'keyword-landscape', 'title': 'Landscape' },
                {'path': 'keyword-cloud', 'title': 'Word Cloud' }
            ]
        },
        {
            'others': [
                {'path': 'patent-validity', 'title': 'Patent Validity' },
                {'path': 'publication-types', 'title': 'Publication Types' }
            ]
        },
        {
            'application_status': [
                {'path': 'jurisdictions-by-application-status', 'title': 'Jurisdictions by Application Status' },
                {'path': 'assignees-by-application-status', 'title': 'Assignees by Application Status' }
            ]
        }
    ];

    // Used to identify the fields that are used for classification analysis
    const classificationFields = [
        'ipctechnologyfield',
        'ipcrfirstchar',
        'ipcrfirst3chars',
        'ipcrfirst4chars',
        'ipcrstringbeforeslash',
        'ipcrstringuptosubgroup',
        'cpcfirstchar',
        'cpcfirst3chars',
        'cpcfirst4chars',
        'cpcstringbeforeslash',
        'cpcstringuptosubgroup',
        'jpfttheme',
        'jpftviewpoint',
        'jpftvalues',
        'jpfifirstchar',
        'jpfifirst3chars',
        'jpfifirst4chars',
        'jpfistringbeforeslash',
        'jpfistringuptosubgroup',
    ]

    const assigneeFields = [
        'assignees_name_probable',
        'assignees_name_probable_single_valued',
        'assignees_name_original',
        'ultimate_owner',
        'ultimate_owner_single_valued'
    ]

    const yearFields = [
        'publicationyear',
        'applicationyear',
        'earliestpriorityyear',
        'grants_publicationyear',
        'expectedexpiryyear'
    ]

    const clusteringFields = [
        'text',
        'technology'
    ]

    const metricList = {
        'minesoft': 'Families',
        'applications': 'Applications',
        'application': 'Application',
        'publications': 'Publications',
        'publication': 'Publication',
        'grants': 'Grants',
        'grant': 'Grant',
        'jurisdictions': 'Jurisdictions',
        'jurisdiction': 'Jurisdiction',
        'priority': 'Earliest Priority',
        'expiry': 'Expiry',
    }

    const analysisSectionNames = (analysisSection) => {

        const parts = analysisSection.split('-by-');

        if (['application-years', 'publication-years', 'grant-years', 'priority-years', 'expiry-years'].includes(parts[0])) {
            return metricList[parts[0].split('-')[0]] + ' Years';
        }

        switch(parts[0]) {
            case 'key-dates':
                return 'Key Dates';
            case 'citation-totals':
                return 'Citation Totals';
            case 'patent-validity':
                return 'Patent Validity';
            case 'publication-types':
                return 'Publication Type';
            case 'keyword-cloud':
                return 'Keyword Cloud';
            case 'keyword-clustering':
                return 'Keyword Clusters';
            case 'keyword-landscape':
                return 'Keyword Landscape';
        }

        if (!parts[1]) {
            return 'Top ' +analysisSectionPartsMapPlural(parts[0]);
        }

        return analysisSectionPartsMapPlural(parts[0]) + ' by ' + analysisSectionPartsMapPlural(parts[1]);

    }

    const analysisSectionPartsMapSingular = (part) => {

        if (typeof part === 'undefined') {
            return false;
        }

        if (['assignees', 'jurisdictions', 'classifications', 'years', 'inventors', 'agents'].includes(part)) {
            return startCase(toLower(part)).replace(/s$/, '');
        }

        if (['application-years', 'publication-years', 'grant-years', 'priority-years', 'expiry-years'].includes(part)) {
            return 'Year';
        }

        switch(part) {
            case 'keyword-clustering':
                return 'Cluster';
            case 'application-status':
                return 'Application Status';
            case 'citation-totals':
                return 'Citation Type';
            case 'citing-assignees':
                return 'Citing Assignee';
            case 'cited-assignees':
                return 'Cited Assignee';
            case 'patent-validity':
                return 'Patent Validity';
            case 'publication-types':
                return 'Publication Type';
            case 'filing-metrics':
                return 'Filing Metric';
            case 'key-dates':
                return 'Key Date';
            case 'keyword-cloud':
                return 'Keyword Cloud';
            case 'keyword-landscape':
                return 'Keyword Landscape';


        }

        console.error('missing parts map:', part);

        return null;

    }

    const analysisSectionPartsMapPlural = (part) => {

        if (['assignees', 'jurisdictions', 'classifications', 'years', 'inventors', 'agents'].includes(part)) {
            return startCase(toLower(part));
        }

        switch(part) {
            case 'keyword-clustering':
                return 'Keyword Clusters';
            case 'application-status':
                return 'Application Status';
            case 'citation-totals':
                return 'Citation Types';
            case 'citing-assignees':
                return 'Citing Assignees';
            case 'cited-assignees':
                return 'Cited Assignees';
            case 'patent-validity':
                return 'Patent Validity';
            case 'publication-types':
                return 'Publication Types';
            case 'filing-metrics':
                return 'Filing Metrics';
            case 'key-dates':
                return 'Key Dates';
            case 'keyword-cloud':
                return 'Keyword Cloud';
            case 'keyword-landscape':
                return 'Keyword Landscape';
        }

        return null;

    }

    const fetchFields = () => {

        return new Promise((resolve, reject) => {

            if (!isEmpty(fieldNames.value)) {
                resolve('success');
            }

            axios.get('/analysis/field_names')
                .then(response => {

                    fieldNames.values = response.data.fields

                    // This is not big - 3KB ish, but other data will be stored in here.
                    // So we'll catch the error just in case.
                    sessionStorage.setItem('fieldNames', JSON.stringify(response.data.fields))
                    return resolve('success');

                }).catch((error) => {

                    return reject(error);

                });
        });
    }

    const getSingular = (field) => {
        return getFieldName(field, false)
    }

    const getPlural = (field) => {
        return getFieldName(field, true)
    }

    const getFieldName = (field, plural) => {

        let key = plural ? 1 : 0

        if (isEmpty(fieldNames.value)) {
            return 'component_error:fields_not_found';
        }

        if (typeof fieldNames.value[field] === 'undefined') {
            // @note: this string is detected in some locations and used to display an error message.
            // So if you change it - you'll also need to change it in some places where "getFieldName" is called.
            console.error('component_error:field_name_not_found ('+field+')')
            return 'field_not_found ('+field+')'
        }

        return fieldNames.value[field][key]

    }

    const getAnalysisSectionManuAndSectionNames = () => {
        return cloneDeep(analysisSectionManuAndSectionNames)
    }

    return {
        hasData,
        getAnalysisSectionManuAndSectionNames,
        groupingMethods,
        groupingMethod,
        fieldNames,
        classificationFields,
        assigneeFields,
        yearFields,
        clusteringFields,
        metricList,
        analysisSectionNames,
        fetchFields,
        getSingular,
        getPlural,
        getFieldName,
        analysisSectionPartsMapPlural,
        analysisSectionPartsMapSingular
    }

});
