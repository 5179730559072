import { defineAsyncComponent, ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const useAlertsStore = defineStore('alerts', () => {

    /**
     * Array of alerts
     */
    const alerts = ref(null)

    /**
     * Boolean property to allow UI to show that data is being refreshed
     */
    const updating = ref(false)

    /**
     * Checksum value used to quickly determine if data is stale on the server side
     */
    const alertsChecksum = ref();

    /**
     * If we already have data then resolve the promise immediately for quicker site response
     */
    const hasData = computed(() => {
        return !(alerts.value == null);
    });

    /**
     * Fetch user strategies from the API
     */
    const fetchAlerts = () => {
        updating.value = true

        return new Promise((resolve, reject) => {
            axios.get('/alerts?checksum='+alertsChecksum.value).then(response => {
                alertsChecksum.value = response.data.checksum;
                if (!response.data.match) {
                    alerts.value = response.data.data;
                }
                updating.value = false;
                resolve();
            }, err => {
                updating.value = false;
                reject();
            });

            if (hasData.value){
                resolve();
            }
        });
    }

    /**
     * Get an alert by ID
     */
    const getAlert = (alertId) => {
        if (alerts.value !== null) {
            return alerts.value.find(alert => alert.id === Number(alertId))
        }

        return null
    }

    /**
     * Get all alerts
     */
    const getAlerts = computed(() => {
        if (alerts.value !== null) {
            return alerts.value
        }
        return []
    })

    /**
     * Get all query alerts
     */
    const getQueryAlerts = computed(() => {
        return getAlerts.value.filter(alert => alert.type === 1)
    })

    /**
     * Get all watched patent alerts
     */
    const getWatchedPatents = computed(() => {
        return getAlerts.value.filter(alert => alert.type === 2)
    })

    return { fetchAlerts, getAlert, getAlerts, getQueryAlerts, getWatchedPatents, alerts, hasData, updating }
})
