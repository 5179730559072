import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const useLoggedEventsStore = defineStore('logged_events', () => {

    /**
     * The current user's logged events
     */
    const loggedEvents = ref(null)

    /**
     * Logged events for a specific folder
     */
    const folderEvents = ref(null)

    /**
     * Logged events for a specific workspace
     */
    const workspaceEvents = ref(null)

    const workspaceLabelEvents = ref(null);

    /**
     * Boolean property to allow UI to show that data is being refreshed
     */
    const updating = ref(false)

    /**
     * Checksum value used to quickly determine if data is stale on the server side
     */
    const userChecksum = ref()

    /**
     * Checksum value used to quickly determine if data is stale on the server side
     */
    const folderChecksum = ref()

    /**
     * Checksum value used to quickly determine if data is stale on the server side
     */
    const workspaceChecksum= ref()

    const workspaceLabelChecksum= ref()


    /**
     * If we already have data then resolve the promise immediately for quicker site response
     */
    const hasData = computed(() => {
        return loggedEvents.value !== null
    });

    /**
     * Fetch logged user events
     */
    const fetchLoggedEvents = () => {
        updating.value = true

        return new Promise((resolve, reject) => {
            axios.get('/logged_events?checksum='+userChecksum.value).then(response => {
                userChecksum.value = response.data.checksum
                if (! response.data.match) {
                    loggedEvents.value = response.data.data
                }
                updating.value = false
                resolve()
            }, err => {
                updating.value = false;
                reject()
            });

            if (hasData.value){
                resolve()
            }
        })
    }

    /**
     * Fetch logged events for a folder
     */
    const fetchFolderEvents = (folder_id) => {
        updating.value = true

        return new Promise((resolve, reject) => {
            axios.get(`/logged_events/${ folder_id }?checksum=${ folderChecksum.value }`).then(response => {
                folderChecksum.value = response.data.checksum
                if (! response.data.match) {
                    folderEvents.value = response.data.data
                }
                updating.value = false
                resolve()
            }, err => {
                updating.value = false;
                reject()
            });

            if (Array.isArray(folderEvents.value)) {
                resolve()
            }
        })
    }

    /*
    * Fetch logged events for a workspace
    */
    const fetchWorkspaceEvents = (workspace_id) => {
        updating.value = true

        return new Promise((resolve, reject) => {
            axios.get(`/workspaces/${ workspace_id }/activity?checksum=${ workspaceChecksum.value }`).then(response => {
                workspaceChecksum.value = response.data.checksum
                if (! response.data.match) {
                    workspaceEvents.value = response.data.data
                }
                updating.value = false
                resolve()
            }, err => {
                updating.value = false;
                reject()
            });

            if (Array.isArray(workspaceEvents.value)) {
                resolve()
            }
        })
    }

    const fetchUserWorkspaceLabelEvents = () => {
        updating.value = true

        return new Promise((resolve, reject) => {
            axios.get('user/workspace_label_activity?checksum='.workspaceChecksum.value)
                .then(response => {
                    workspaceLabelChecksum.value = response.data.checksum
                    if (! response.data.match) {
                        workspaceLabelEvents.value = response.data.data
                    }
                    updating.value = false
                    resolve()
                }).catch(error => {
                    updating.value = false;
                    reject()
                });

            if (Array.isArray(workspaceLabelEvents.value)) {
                resolve()
            }
        })
    }


    const getLoggedEvents = computed(() => {
        if (hasData.value) {
            return loggedEvents.value
        }

        return []
    })

    const getFolderEvents = computed(() => {
        if (Array.isArray(folderEvents.value)) {
            return folderEvents.value
        }
        return []
    })

    const getWorkspaceEvents = computed(() => {
        if (Array.isArray(workspaceEvents.value)) {
            return workspaceEvents.value
        }
        return []
    })

    const getUserWorkspaceLabelEvents = computed(() => {
        if (Array.isArray(workspaceLabelEvents.value)) {
            return workspaceLabelEvents.value
        }
        return []
    })

    return { fetchLoggedEvents, fetchFolderEvents, fetchWorkspaceEvents, fetchUserWorkspaceLabelEvents,
                    getLoggedEvents, getFolderEvents, getWorkspaceEvents, getUserWorkspaceLabelEvents, updating, hasData }
})
