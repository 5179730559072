import {useRoute, useRouter} from 'vue-router';

export function useOpenAnalysis()  {

    const route = useRoute();
    const router = useRouter();

    // Get the active workspace ID from local storage
    const getActiveWorkspaceID = (analysisID) => {

        let analysisData = sessionStorage.getItem(analysisID);

        if (analysisData !== null) {
            return JSON.parse(analysisData).workspaceID;
        }

        return false;

    };

    const getActiveWorkspaceQueryCount = (analysisID) => {

        let analysisData = sessionStorage.getItem(analysisID);

        if (analysisData !== null) {
            return JSON.parse(analysisData).noOfQueries;
        }

        return false;

    };

    // Set the active grouping from local storage
    const setActiveGrouping = (analysisID, grouping) => {

        let analysisData = sessionStorage.getItem(analysisID);

        if (analysisData !== null) {
            let data = JSON.parse(analysisData);
            data.grouping = grouping;
            sessionStorage.setItem(analysisID, JSON.stringify(data));
        }
    };

    // Get the active grouping from local storage
    const getActiveGrouping = (analysisID) => {

        let analysisData = sessionStorage.getItem(analysisID);

        if (analysisData !== null) {
            return JSON.parse(analysisData).grouping;
        }

    };

    // Grab a specific workspace details
    // These typically can be used to open/ run/ load the analysis
    const workspace = (workspace_id) => {

        return new Promise((resolve, reject) => {

            axios.get('/workspaces/' + workspace_id)
                .then(response => {

                    // Should only be one workspace!
                    // This is the workspace data which we need to write the workspace ID to local storage
                    let workspaceData = response.data.data[0];

                    let queryData = [];

                    for(const thisQuery of workspaceData.queries) {

                        let params = {};

                        params.searchQuery = thisQuery.full_query;
                        params.group = thisQuery.grouping ?? 'simplefamily';
                        params.searchFilters = thisQuery.filters;

                        queryData.push(params);

                    }

                    resolve({'workspaceData': workspaceData, 'queryData': queryData});

                }).catch(error => {

                    reject(error);

                })

        });
    };

    /**
     * To improve the workflow, we'll redirect to analytics first and then load the analysis.
     */
    const redirect_first = (params, target) => {

        // Save the analysis request params to session storage.
        // We'll grab this later.
        sessionStorage.setItem('analysis_request_params', JSON.stringify(params));

        // Redirect to the analytics loading page...
        redirect('init', target??'_blank');

    };

    /**
     * This is the second part of the redirect_first method above.
     * i.e. grab the session params and redirect to the main page (via the session set up in /load, etc).
     */
    const redirect_first_to_direct = () => {

        // Grab the session params...
        const params = sessionStorage.getItem('analysis_request_params');

        // Clear the session storage
        sessionStorage.removeItem('analysis_request_params');

        // ..and redirect ot the main page.
        direct(JSON.parse(params), '_self');

    };

    const session_only = (params) => {

        /**
         * Go directly to an analysis based on a set of parameters:
         *   'name', 'searchQuery', 'group','searchFilters','filterCount'
         *  OR
         *   ...just an 'workspace ID' if it is present. For example:
         *          workspaceData: {
         *              id: active_workspace_id
         *          }
         */
        // This creates the matching PHP session (that contains the search query for data requests)
        saveAndCreateSession(params)
            .then((saveAndCreateSessionData) => {

                // Store the analysis(session)ID, the workspace ID and grouping in local session storage
                local_session_store(saveAndCreateSessionData, params.group);

            }).catch((error) => {

                // @todo: this should perhaps be handled from the location that it was called from...
                // ...this is mostly the "Analyse" buttons in various locations.
                console.error(error);

            });

    }

    const share_direct = (params, target, redirectMethod, shareDetails) => {

        // "Dashboard" redirects to the dashboard, "reload", replaces the analysis ID in the current URL
        if (!['shared_dashboard', 'shared_chart'].includes(redirectMethod)) {
            return false;
        }

        /**
         * Go directly to an analysis based on a set of parameters:
         *   'name', 'searchQuery', 'group','searchFilters','filterCount'
         *  OR
         *   ...just an 'workspace ID' if it is present. For example:
         *          workspaceData: {
         *              id: active_workspace_id
         *          }
         */
        // This creates the matching PHP session (that contains the search query for data requests)
        createShareSession(params)
            .then((shareSessionData) => {

                // Store the analysis(session)ID, the workspace ID and grouping in local session storage
                local_session_store(shareSessionData, params.group);

                // Redirect to the analysis
                redirect(shareSessionData.analysisID, target??'_blank', redirectMethod, shareDetails);

            }).catch((error) => {

                // @todo: this should perhaps be handled from the location that it was called from...
                // ...this is mostly the "Analyse" buttons in various locations.
                console.error(error);

            });

    }

    // Go directly to an analysis based on a set of parameters
    const direct = (params, target, redirectMethod) => {

        // "Dashboard" redirects to the dashboard, "reload", replaces the analysis ID in the current URL
        if (!['reload', 'dashboard'].includes(redirectMethod)) {
            redirectMethod = 'dashboard';
        }

        /**
         * Go directly to an analysis based on a set of parameters:
         *   'name', 'searchQuery', 'group','searchFilters','filterCount'
         *  OR
         *   ...just an 'workspace ID' if it is present. For example:
         *          workspaceData: {
         *              id: active_workspace_id
         *          }
         */
        // This creates the matching PHP session (that contains the search query for data requests)
        saveAndCreateSession(params)
            .then((saveAndCreateSessionData) => {

                // Store the analysis(session)ID, the workspace ID and grouping in local session storage
                local_session_store(saveAndCreateSessionData, params.group);

                // Redirect to the analysis
                redirect(saveAndCreateSessionData.analysisID, target??'_blank', redirectMethod);

            }).catch((error) => {

                // @todo: this should perhaps be handled from the location that it was called from...
                // ...this is mostly the "Analyse" buttons in various locations.
                console.error(error);

            });

    }

        // Save the analysis session and create a session
    const saveAndCreateSession = (params) => {

        return new Promise((resolve, reject) => {

            /**
             * On the backend this will look for matching analysis in a workspace and "load" it, or it will create a
             * ...new one using the query (and any filter).
             *
             * Once done it will create a PHP session with the full summary data and return the analysis ID.
             *
             * This can then be referenced in following requests which will use the session data to generate part of
             * ...the required request.
             */
            axios.post('/analysis/load', params)
                .then(response => {

                    // This is the generated session key for the analysis
                    // This is based from md5(query.group.searchFilters)
                    let analysisID = response.data.analysisID;
                    let workspaceID = response.data.workspaceID;
                    let noOfQueries = response.data.no_of_queries;

                    resolve({'analysisID': analysisID, 'workspaceID': workspaceID, 'noOfQueries': noOfQueries});

                }).catch(error => {
                    reject(error);
                })

        });

    };

    const createShareSession = (params) => {

        return new Promise((resolve, reject) => {

            /**
             * On the backend this will look for matching analysis in a workspace and "load" it, or it will create a
             * ...new one using the query (and any filter).
             *
             * Once done it will create a PHP session with the full summary data and return the analysis ID.
             *
             * This can then be referenced in following requests which will use the session data to generate part of
             * ...the required request.
             */
            axios.post('/analysis/share_load', params)
                .then(response => {

                    // This is the generated session key for the analysis
                    // This is based from md5(query.group.searchFilters)
                    let analysisID = response.data.analysisID;
                    let workspaceID = response.data.workspaceID;
                    let noOfQueries = response.data.no_of_queries;

                    resolve({'analysisID': analysisID, 'workspaceID': workspaceID, 'noOfQueries': noOfQueries});

                }).catch(error => {
                reject(error);
            })

        });

    };

    // REQUIRED: Save the workspace ID and grouping to local storage based on the analysis/ session ID
    const local_session_store = (sessionData, grouping) => {

        sessionStorage.setItem(sessionData.analysisID, JSON.stringify({
            'grouping': grouping ?? 'simplefamily',
            'workspaceID': sessionData.workspaceID,
            'noOfQueries': sessionData.noOfQueries
        }));

        return true;

    }

    const goToNewURL = (newAnalysisID) => {

        const currentPath = route.path;

        // Switch the analysis ID in the current URL and remove any specific chart ID
        window.location.href = currentPath.replace(/[a-z0-9]{32}/, newAnalysisID).replace(/\/chart\/\d+$/, '/chart');

        /*
        @todo: It would be nice to use the router to do this, but it doesn't work as the dashboard needs to fully reload.
        @todo: Could watch for analysisID changes and then update the dashboard.
        router
            .push(newPath)
            .then(() => {
               // window.location.reload();
                console.info('Navigated to the new Analysis URL.');
            });*/

    }

    const goToDefaultSectionURL = () => {

        const currentPath = route.path;

        // It's possible to arrive at a linked chart via the normal URL, if the linked chart happens to be the
        // current default.
        if (currentPath.match(/\/chart\/\d+$/) === null) {
            router.go(0);
        }

        // Switch the analysis ID in the current URL and remove any specific chart ID
        const newPath = currentPath.replace(/\/chart\/\d+$/, '/chart');
        router
            .push(newPath)
            .then(() => {
                console.info('Navigated to the default section URL.');
            });

    }

    // Open the analysis in the same window or a new window directly as per params
    const redirect = (analysisID, target, redirectMethod, shareDetails) => {

        console.info('Redirecting to analysis: ' + analysisID + ' in ' + target + ' window, by means of ' + redirectMethod + '.')

        if (typeof target === 'undefined') {
            target = '_self';
        }

        if (!['reload', 'dashboard', 'shared_dashboard', 'shared_chart'].includes(redirectMethod)) {
            redirectMethod = 'dashboard';
        }

        if (target === '_self') {

            if (redirectMethod === 'reload') {
                goToNewURL(analysisID);
            } else if (redirectMethod === 'shared_dashboard') {
                window.location.href = '/shared_dashboard/' + analysisID + '/' + shareDetails.id
            } else if (redirectMethod === 'shared_chart') {
                window.location.href = '/shared_chart/' + analysisID + '/section/'+shareDetails.analysis_section+'/' + shareDetails.id
            } else {
                window.location.href = '/analysis/' + analysisID;
            }

        } else {

            let newWindow = window.open();
            if (redirectMethod === 'reload') {
                goToNewURL(analysisID);
            } else {
                // analysisID might be "init" if we're redirecting from the main app for example
                newWindow.location = '/analysis/' + analysisID
            }

        }
    }

    return {
        getActiveWorkspaceID,
        getActiveWorkspaceQueryCount,
        setActiveGrouping,
        getActiveGrouping,
        workspace,
        redirect_first,
        redirect_first_to_direct,
        session_only,
        direct,
        share_direct,
        saveAndCreateSession,
        local_session_store,
        goToNewURL,
        goToDefaultSectionURL,
        redirect
    }

}
