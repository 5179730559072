import { defineAsyncComponent, ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const useStrategiesStore = defineStore('strategies', () => {

    /**
     * Array of strategies the user has access to
     */
    const strategies = ref(null)

    /**
     * Boolean property to allow UI to show that data is being refreshed
     */
    const updating = ref(false)

    /**
     * Checksum value used to quickly determine if data is stale on the server side
     */
    const strategiesChecksum = ref();
    const historyChecksum = ref();

    /**
     * The searches on the current strategy
     */
    const searches = ref(null);

    /**
     * If we already have data then resolve the promise immediately for quicker site response
     */
    const hasData = computed(() => {
        return !(strategies.value == null)
    });

    /**
     * Fetch user strategies from the API
     */
    const fetchStrategies = () => {

        updating.value = true

        return new Promise((resolve, reject) => {
            axios.get('/strategies?checksum='+strategiesChecksum.value).then(response => {
                strategiesChecksum.value = response.data.checksum
                if (!response.data.match) {
                    strategies.value = response.data.data
                }
                updating.value = false;
                resolve()
            }, err => {
                updating.value = false;
                reject()
            });

            if (hasData.value){
                resolve()
            }
        });
    }

    /**
     * Fetch the current strategy from the API
     */
    const fetchCurrentStrategy = () => {
        updating.value = true

        return new Promise((resolve, reject) => {
            axios.get('/searches?checksum='+historyChecksum.value).then(response => {
                historyChecksum.value = response.data.checksum;
                if (!response.data.match) {
                    searches.value = response.data.data;
                }
                updating.value = false;
                resolve()
            }, err => {
                updating.value = false;
                reject()
            });

            if (hasData.value){
                resolve()
            }
        });

    }

    const getStrategies = computed(() => {
        if (strategies.value !== null) {
            return strategies.value
        }
        return [];
    })

    const getSearches = computed(() => {
        if (searches.value !== null) {
            return searches.value
        }
        return [];
    })

    const getSearch = (searchIndex) => {
        if (searches.value !== null) {
            return searches.value.find(search => search.query_index === Number(searchIndex))
        }

        return null
    }

    return { fetchStrategies, getStrategies, strategies, updating, hasData, searches, getSearches, fetchCurrentStrategy, getSearch }
})
