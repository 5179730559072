import { defineAsyncComponent, ref, computed } from 'vue'
import { defineStore } from 'pinia'
import {useUserStore} from "./Users";

export const useGroupStore = defineStore('groups', () => {

    const userStore = useUserStore();

    /**
     * Set of groups that are defined for a given organization
     */
    const groups = ref(null)

    /**
     * Boolean flag to indicate that data is being fetched
     */

    const updating = ref(false)

    /**
     * Checksum value used to quickly determine if data is stale on the server side
     */

    const checksum = ref();

    /**
     * If we already have data then resolve the promise immediately for quicker site response
     */

    const hasData = computed(() => {
        return Array.isArray(groups.value)
    })

    /**
     * Fetch organization groups data from API
     */
    const fetchGroups = () => {

        updating.value = true

        return new Promise((resolve, reject) => {
            axios.get('/groups?checksum='+checksum.value).then(response => {
                checksum.value = response.data.checksum
                if (!response.data.match) {
                    groups.value = response.data.data
                }
                updating.value = false;
                resolve()
            }, err => {
                updating.value = false;
                reject()
            })

            if (hasData.value){
                resolve()
            }
        });
    }

    /**
     * Computed method to return the array of groups, populated with full user details
     */
    const getGroups = computed(() => {
        if (hasData.value) {
            return groups.value
        }
        return []
    })

    /**
     * Helper method to extract details for a given group by id
     */
    const getGroupById = (id) => {
        return groups.value.find(group => group.id === id)
    }

    return { fetchGroups, getGroupById, getGroups, groups, updating, hasData }
})
