import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useSubscriptionRunStore = defineStore('subscription_run', () => {

    /**
     * The subscription that the run belongs to
     */
    const subscription = ref(null)

    /**
     * The subscription run
     */
    const subscriptionRun = ref(null)

    /**
     * Checksum value used to quickly determine if data is stale on the server side
     */
    const checksum = ref(null)

    /**
     * Fetch the subscription run
     */
    const fetchSubscriptionRun = (subscription_id, subscription_run_id) => {
        return new Promise((resolve, reject) => {
            axios.get(`/origin-plus/subscriptions/${ subscription_id }/subscription_runs/${ subscription_run_id }?checksum=${ checksum.value }`)
                .then(response => {
                    checksum.value = response.data.checksum

                    if (! response.data.match) {
                        subscriptionRun.value = response.data.data
                        subscription.value = subscriptionRun.value.subscription

                        // Deduplicate the alert model
                        delete subscription.value['alert']
                    }

                    resolve()
                })
                .catch(error => {
                    reject(error)
                })

            if (subscriptionRun.value && subscriptionRun.value.id === subscription_run_id) {
                resolve()
            }
        });
    }

    return { fetchSubscriptionRun, subscription, subscriptionRun }
})
