import { reactive, computed, ref } from 'vue'
import moment from 'moment'
import { defineStore } from 'pinia'
import { useAuthStore } from '@/Stores/Auth'
import { useListLayout, useTableLayout, useSplitLayout } from '@/Composables/PatentResultsLayouts'
import { patentSearchFilters } from "@/Composables/PatentSearchFilters"

export const useSearchStateStore = defineStore('search_state', () => {

    const auth = useAuthStore()

    const currentSearch = ref(null)

    /**
     * The default search state object
     */
    const defaultState = {
        start: 0,
        rows: auth.user?.preferences?.rows_per_page ?? 10,
        sort: auth.user?.preferences?.sort_order ?? 'score desc,publicationdate desc',
        grouping: auth.user?.preferences?.patent_grouping ?? 'simplefamily',
        highlighting: {
            on: auth.user?.preferences?.highlighting_settings.on ?? true,
            scheme: auth.user?.preferences?.highlighting_settings.scheme ?? null,
            query: null,
            max_snippets: 3,
            max_snippet_length: 150,
        },
        layout: {
            selected: auth.user?.preferences?.results_layout ?? 'list',
        },
        filters: {},
        publishedSubQuery: '',
        timestamp: moment().format(),
        activeUcid: '',
        activeIndex: 0,
    }

    /**
     * The reactive state object to mutate
     */
    const state = reactive(Object.assign({}, defaultState))

    /**
     * Object keeping track of the layout-specific options
     */
    const layouts = reactive({
        list: useListLayout(),
        table: useTableLayout(),
        split: useSplitLayout(),
        updateTableColumns(fields) {
            this.table.updateFields(fields)
        },
        updateListConfig(config) {
            this.list.updateConfiguration(config)
        },
        is(layout) {
            return state.layout.selected === layout
        }
    })

    /**
     * The fields to search against depend on the state's layout
     */
    const fields = computed(() => layouts[state.layout.selected].fields)

    /**
     * We need to persist the 'state' of search results. We currently do this using localStorage.
     * These are the keys that we use for the different search results viewing contexts.
     */
    const keys = [
        'searches',
        'alerts',
        'folders',
        'published_searches',
        'shared_alert_runs',
        'tasks',
        'subscriptions',
    ]

    /**
     * Get the localStorage key for a given context and model
     */
    function useKey(name, model = {}) {
        if (! keys.includes(name) || ! model.id) {
            throw "Could not generate state key"
        }

        return `search_state.${ name }.${ model.id }`
    }

    /**
     * Restore the state based on a given key. We'll attempt to get the state out of localStorage
     * and fill the state from there.
     */
    function restore(key, defaultStateOverrides = {}) {
        // Reset the state to the default
        let emptyState = Object.assign({}, { ...defaultState, ...defaultStateOverrides })

        Object.keys(emptyState).forEach(key => state[key] = emptyState[key])

        // Prune expired states to prevent infinitely storing in localStorage
        runPruningLottery()

        // If there is saved state to restore, restore it
        let savedState = localStorage.getItem(key)

        if (savedState) {
            savedState = JSON.parse(savedState)

            Object.keys(emptyState).forEach((key) => {
                if (typeof savedState[key] === typeof emptyState[key]) {

                    // Validate the saved state
                    state[key] = validState(key, savedState[key])
                }
            })
        }
    }

    /**
     * Update the search state to use the user's preferences
     */
    function usePreferences() {
        let preferences = auth.user.preferences

        layouts.updateTableColumns(preferences.table_layout)
        layouts.updateListConfig(preferences.list_layout)

        state.highlighting.max_snippets = preferences.max_snippets ?? state.highlighting.max_snippets
        state.highlighting.max_snippet_length = preferences.max_snippet_length ?? state.highlighting.max_snippet_length
        state.layout.selected = auth.user?.preferences?.results_layout ?? 'list'
        state.rows = auth.user?.preferences?.rows_per_page ?? 10
    }

    /**
     * Check if the state object has expired. We'll consider the state expired if it is older than 1 week.
     */
    function isExpired(state) {
        let previous = moment(state.timestamp.substring(0, 10))

        if (previous.isValid()) {
            return moment().diff(previous, 'days') > 7
        }

        return true
    }

    /**
     * Validate the state and remove any deprecated or invalid values
     */
    function validState(key, state) {

        if(key === 'filters') {
            // Handle old object structure that is no longer supported
            if (! state.hasOwnProperty('filtersToApply') && !state.hasOwnProperty('filtersToExclude')) {
                return {}
            }

            // Remove any filters that are no longer valid
            ['filtersToApply', 'filtersToExclude'].forEach(property => {
                Object.keys(state[property]).forEach(filter => {
                    if (! patentSearchFilters.includes(filter)) {
                        delete state[property][filter]
                    }
                })
            })
        }

        if (key === 'sort') {
            if (state === 'if(exists(forwardcitationscount),forwardcitationscount,0) asc') {
                state = 'forwardcitationscount asc';
            }
        }

        return state
    }

    /**
     * Run a lottery to call the prune method.
     */
    function runPruningLottery() {
        let min = 1
        let max = 10
        let winner = 1
        let result = Math.floor(Math.random() * (max - min + 1) + min)

        if (result === winner) {
            prune()
        }
    }

    /**
     * Prune expired states in localStorage
     */
    function prune() {
        Object.keys(localStorage)
            .filter(key => key.startsWith('search_state'))
            .forEach(key => {
                let savedState = localStorage.getItem(key)

                // Unset the state if it is expired
                try {
                    if (! savedState || isExpired(JSON.parse(savedState).timestamp)) {
                        localStorage.removeItem(key)
                    }
                }

                // If there's any error, something is wrong and we should remove the item in local storage
                catch (e) {
                    localStorage.removeItem(key)
                }

            })
    }

    /**
     * Persist the search state in local storage
     */
    function persist(key) {
        localStorage.setItem(key, JSON.stringify(state))
    }

    function updateSearch(search) {
        currentSearch.value = search
    }

    function useAlertHighlightingScheme(scheme = null) {
        if (scheme) {
            state.highlighting.scheme = scheme
            state.highlighting.on = true
        }
    }

    return { state, persist, restore, useKey, usePreferences, layouts, fields, defaultState, currentSearch, updateSearch, useAlertHighlightingScheme }
})
