import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const useFoldersStore = defineStore('folders', () => {

    /**
     * Array of folders that the current user has access to
     */
    const folders = ref(null)

    /**
     * Boolean property to allow UI to show that data is being refreshed
     */
    const updating = ref(false)

    /**
     * Checksum value used to quickly determine if data is stale on the server side
     */
    const checksum = ref()

    /**
     * If we already have data then resolve the promise immediately for quicker site response
     */
    const hasData = computed(() => {
        return !(folders.value == null)
    })

    /**
     * Fetch folders from API
     */
    const fetchFolders = () => {
        updating.value = true

        return new Promise((resolve, reject) => {
            axios.get('/folders?checksum='+checksum.value).then(response => {
                checksum.value = response.data.checksum
                if (!response.data.match) {
                    folders.value = response.data.data
                }
                updating.value = false
                resolve()
            }, err => {
                updating.value = false
                reject()
            })

            if (hasData.value){
                resolve()
            }
        })
    }

    const getFolders = computed(() => {
        if (hasData.value) {
            return folders.value
        }

        return []
    })

    const getPinnedFolders = computed(() => {
        if (hasData.value) {
            return folders.value.filter(folder => folder.pinned_users.length)
        }

        return []
    })

    /**
     * Helper method to extract a given user from the stored array
     */
    const getFolderById = (id) => {
        return getFolders.value.find(folder => folder.id === id)
    }

    return { fetchFolders, getFolderById, getFolders, getPinnedFolders, updating, hasData, folders }
})
