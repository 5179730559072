import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const useCustomFieldSchemesStore = defineStore('custom_field_schemes', () => {

    /**
     * Array of custom schemes that the current user has access to
     */
    const schemes = ref(null)

    /**
     * The active scheme that is currently being used by the user
     */
    const activeScheme = ref(null)

    /**
     * Boolean property to allow UI to show that data is being refreshed
     */
    const updating = ref(false)

    /**
     * Checksum value used to quickly determine if data is stale on the server side
     */
    const checksum = ref()

    /**
     * If we already have data then resolve the promise immediately for quicker site response
     */
    const hasData = computed(() => {
        return !(schemes.value == null)
    })

    /**
     * Fetch schemes from API
     */
    const fetchSchemes = (withFields = false) => {
        updating.value = true

        return new Promise((resolve, reject) => {
            axios.get('/custom_field_schemes?checksum='+checksum.value+'&withFields='+(withFields?1:0)).then(response => {
                checksum.value = response.data.checksum
                if (!response.data.match) {
                    schemes.value = response.data.data
                }
                updating.value = false
                resolve()
            }, err => {
                updating.value = false
                reject()
            })

            if (hasData.value){
                resolve()
            }
        })
    }

    const getSchemes = computed(() => {
        if (hasData.value) {
            return schemes.value
        }

        return []
    })

    const setActiveScheme = (id) => {
        return new Promise((resolve, reject) => {
            axios.post(`/custom_field_schemes/${id}/active`).then(response => {
                activeScheme.value = response.data
                resolve()
            }, err => {
                reject()
            })
        })
    }

    /**
     * fetch the user's active scheme or a specific scheme if an alert run UUID is provided
     */
    const fetchActiveScheme = (uuid = null) => {

        updating.value = true

        return new Promise((resolve, reject) => {
            axios.get('/active_custom_field_scheme', {
                params: {
                    uuid
                }

            }).then(response => {
                if (response.data) {
                    activeScheme.value = response.data
                }
                updating.value = false

                resolve()
            }, err => {
                updating.value = false

                reject()
            })
        })
    }

    const removeActiveScheme = (id) => {
        return new Promise((resolve, reject) => {
            axios.delete(`/active_custom_field_scheme/${id}`).then(response => {
                activeScheme.value = null
                resolve()
            }, err => {
                reject()
            })
        })
    }


    /**
     * Helper method to extract a given user from the stored array
     */
    const getSchemeById = (id) => {
        return getSchemes.value.find(scheme => scheme.id === id)
    }

    return {
        fetchSchemes,
        getSchemeById,
        getSchemes,
        updating,
        hasData,
        schemes,
        setActiveScheme,
        fetchActiveScheme,
        removeActiveScheme,
        activeScheme
    }
})
